define("discourse/plugins/discourse-locations/discourse/components/user-location", ["exports", "@ember/object", "@glimmer/component", "@ember/service", "@glimmer/tracking", "I18n", "discourse/components/d-button", "discourse-common/helpers/d-icon", "discourse/plugins/discourse-locations/discourse/lib/location-utilities", "discourse/plugins/discourse-locations/discourse/components/locations-map", "@ember/component", "@ember/template-factory"], function (_exports, _object, _component, _service, _tracking, _I18n, _dButton, _dIcon, _locationUtilities, _locationsMap, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class LocationMapComponent extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "siteSettings", [_service.inject]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "site", [_service.inject]))();
    #site = (() => (dt7948.i(this, "site"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "showMap", [_tracking.tracked], function () {
      return false;
    }))();
    #showMap = (() => (dt7948.i(this, "showMap"), void 0))();
    get mapButtonLabel() {
      return `location.geo.${this.showMap ? "hide" : "show"}_map`;
    }
    get showMapButtonLabel() {
      return this.args.formFactor !== "card" && !this.site.mobileView;
    }
    get userLocation() {
      let locationText1 = "";
      if (this.args.user && this.args.user.geo_location) {
        let format1 = this.siteSettings.location_user_profile_format.split("|");
        let opts1 = {};
        if (format1.length && format1[0]) {
          opts1["geoAttrs"] = format1;
          locationText1 = (0, _locationUtilities.geoLocationFormat)(this.args.user.geo_location, this.site.country_codes, opts1);
        } else {
          locationText1 = this.args.user.geo_location.address;
        }
      }
      return locationText1;
    }
    get canShowMap() {
      return !document.querySelector(".leaflet-container");
    }
    toggleMap() {
      this.showMap = !this.showMap;
    }
    static #_4 = (() => dt7948.n(this.prototype, "toggleMap", [_object.action]))();
    static #_5 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <div class="user-location-widget">
          {{icon "map-marker-alt"}}
          <div class="location-label">
            {{this.userLocation}}
          </div>
          {{#if this.canShowMap}}
            <div class="map-wrapper">
              <DButton
                class="widget-button btn btn-default btn-show-map btn-small btn-icon-text"
                @action={{this.toggleMap}}
                @icon="far-map"
                @label={{if this.showMapButtonLabel this.mapButtonLabel}}
              />
              {{#if this.showMap}}
                <div class="map-container small">
                  <LocationsMap @mapType="user" @user={{@user}} />
                </div>
              {{/if}}
            </div>
          {{/if}}
        </div>
      
    */
    {
      "id": "n5PJLquC",
      "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"user-location-widget\"],[12],[1,\"\\n      \"],[1,[28,[32,0],[\"map-marker-alt\"],null]],[1,\"\\n      \"],[10,0],[14,0,\"location-label\"],[12],[1,\"\\n        \"],[1,[30,0,[\"userLocation\"]]],[1,\"\\n      \"],[13],[1,\"\\n\"],[41,[30,0,[\"canShowMap\"]],[[[1,\"        \"],[10,0],[14,0,\"map-wrapper\"],[12],[1,\"\\n          \"],[8,[32,1],[[24,0,\"widget-button btn btn-default btn-show-map btn-small btn-icon-text\"]],[[\"@action\",\"@icon\",\"@label\"],[[30,0,[\"toggleMap\"]],\"far-map\",[52,[30,0,[\"showMapButtonLabel\"]],[30,0,[\"mapButtonLabel\"]]]]],null],[1,\"\\n\"],[41,[30,0,[\"showMap\"]],[[[1,\"            \"],[10,0],[14,0,\"map-container small\"],[12],[1,\"\\n              \"],[8,[32,2],null,[[\"@mapType\",\"@user\"],[\"user\",[30,1]]],null],[1,\"\\n            \"],[13],[1,\"\\n\"]],[]],null],[1,\"        \"],[13],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n  \"]],[\"@user\"],false,[\"if\"]]",
      "moduleName": "/var/www/community.vuo.org/app/assets/javascripts/discourse/discourse/plugins/discourse-locations/discourse/components/user-location.js",
      "scope": () => [_dIcon.default, _dButton.default, _locationsMap.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = LocationMapComponent;
});